import { useEffect, useRef, useState, } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { useDispatch, } from "react-redux";
import moment from "moment";
import { fetchsavedvideos } from "../../../Action";
import authService from "../../../Services/auth.service";
import authService1 from "../../../Services/auth.service1";
import VideoPlayer from "./VideoPlayerFunction";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// icon
import LikesListPopup from "../LikesListPopup";
import CensusDataModal from "../../User/PartnerManagement/CensusDataModal";

import { ReactComponent as IcnSLinkIcon } from "../../../Images/icn_s_link.svg";
import mdsLogo from "../../../Images/news-logo.svg";

import VideoCommentBox from "./VideoCommentBox";
import DetailsTab from "./DetailsTab";
import InfoTab from "./InfoTab";
import TabsFluid from "../../Tabs/TabsFluid";

const ContentDetail = ({ selectedVideoId, }) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [videodetail, setvideodetail] = useState({});
  const [videooptions, setvideooptions] = useState({});
  const [allComments, setAllComments] = useState([]);
  const [totalCommts, setTotalCommts] = useState(0);
  const [loader, setloader] = useState(true);
  const [selectedCmt, setselectedCmt] = useState(false);
  const [like_popup, setlike_popup] = useState(false);
  const [noCommtFound, setnoCommtFound] = useState(false);
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentTab, setCurrentTab] = useState("detailrelevantpartner")
  const [currentCommtPage, setCurrentCommtPage] = useState(1)
  let changevideotime = useRef(0);

  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true);
    }
    setvideodetail(null);
    setvideooptions(null);
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const getAllComments = () => {
    authService
      .getallcomments_video({ videoid: selectedVideoId, page: 1, limit: 30 })
      .then((res) => {
        if (res.data.status) {
          if (
            res.data.data[0].comment !== undefined &&
            res.data.data[0].comment.length > 0
          ) {
            var data = res.data.data[0].comment.map((comm) => {
              var reply = [];
              var rereply = [];
              if (comm.comments.length > 0) {
                reply = comm.comments.map((reply) => {
                  if (reply.comments.length > 0) {
                    rereply = reply.comments.map((r) => {
                      return {
                        ...r,
                        likes: { count: r.likes.length, list: r.likes },
                      };
                    });
                  }
                  return {
                    ...reply,
                    likes: { count: reply.likes.length, list: reply.likes },
                    comments: rereply,
                  };
                });
              }
              return {
                ...comm,
                likes: { count: comm.likes.length, list: comm.likes },
                comments: reply,
              };
            });
            setAllComments(data);
            setCurrentCommtPage(res.data.data[0].currentPage)
            setTotalCommts(res.data.data[0].totalComments);
            setloader(false);
            setnoCommtFound(false);
          } else {
            setloader(false);
            setTotalCommts(0)
            setnoCommtFound(true);
            setAllComments([]);
          }
        } else {
          setloader(false);
          setTotalCommts(0)
          setnoCommtFound(true);
          setAllComments([]);
        }
      })
      .catch((e) => {
        setloader(false);
        setnoCommtFound(true);
        setTotalCommts(0)
        return false;
      });
  }

  useEffect(() => {
    setloader(true);
    setvideooptions(null);
    setvideodetail(null);
    if (
      selectedVideoId !== undefined &&
      selectedVideoId !== null &&
      selectedVideoId.length > 0
    ) {
      window.scrollTo({
        top: -50,
        left: -50,
        behavior: "smooth",
      });
      dispatch(fetchsavedvideos());

      const data_ = {
        videoId: selectedVideoId,
        date: formatDate(new Date()),
      };
      authService1
        .addorupdatehistoryvideo(data_)
        .then((res) => { })
        .catch((e) => {
          return false;
        });

      authService
        .getcontentvideobyidForUser(selectedVideoId)
        .then((res) => {
          if (res.data.status) {
            var i = 0;
            var res_arr = [];
            if (
              res.data.data.video_240 !== undefined &&
              res.data.data.video_240 !== null &&
              res.data.data.video_240.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_240,
                label: "240",
                res: 240,
              };
              i++;
            }
            if (
              res.data.data.video_360 !== undefined &&
              res.data.data.video_360 !== null &&
              res.data.data.video_360.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_360,
                label: "360",
                res: 360,
              };
              i++;
            }
            if (
              res.data.data.video_480 !== undefined &&
              res.data.data.video_480 !== null &&
              res.data.data.video_480.length > 0
            ) {
              res_arr[i] = {
                src:
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.video_480,
                label: "480",
                res: 480,
              };
              i++;
            }
            if (
              res.data.data.video_url !== undefined &&
              res.data.data.video_url !== null &&
              res.data.data.video_url.length > 0
            ) {
              res_arr[i] = {
                src:
                  res.data.data.video_url,
                label: "Original",
              };
              i++;
            }
            setVideoUrl(res?.data?.data?.video_url);
            setvideooptions({
              videoid: selectedVideoId,
              startTime:
                res.data.data.user_video_pause !== undefined &&
                  res.data.data.user_video_pause[
                  localStorage.getItem("userid")
                  ] !== undefined &&
                  res.data.data.user_video_pause !== null &&
                  res.data.data.user_video_pause[
                  localStorage.getItem("userid")
                  ] !== null
                  ? res.data.data.user_video_pause[
                  localStorage.getItem("userid")
                  ]
                  : 0,
              playbackRates: [1, 1.1, 1.25, 1.5, 1.75, 2],
              width: 640,
              height: 360,
              autoplay: false,
              poster:
                res.data.data.thumbnail !== undefined &&
                  res.data.data.thumbnail !== null
                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.thumbnail
                  : "",
              controls: true,
              plugins: {
                videoJsResolutionSwitcher: {
                  default: "height",
                  dynamicLabel: true,
                },
              },
              sources: res_arr,
              html5: {
                nativeTextTracks: false,
              },
              subtitle: res.data.data.subtitle_file,
              preload: "auto",
              startViewTime: new Date().getTime(),
              isViewAdded: false,
            });
            setvideodetail(res.data.data);
            setloader(false);
          } else {
            setloader(false);
          }
        })
        .catch((e) => {
          setloader(false);
          return false;
        });
      getAllComments()
    }
    setCurrentTab("detailrelevantpartner")
  }, [selectedVideoId]);



  const handlewatched = () => {
    authService
      .addviewbyuser(selectedVideoId, "web")
      .then((res) => {
        if (res.data.status) {
          var data = {
            ...videodetail,
            views: [...videodetail.view, localStorage.getItem("userid")],
          };
          setvideodetail(data);
        }
      })
      .catch((e) => {
        return false;
      });
  };

  // set dynamic size of video thumbnail
  // const getResolution = (url) => {
  //   const video = document.createElement("video");
  //   video.src = url;
  //   video.addEventListener("loadedmetadata", () => {
  //     video.videoWidth && setloader(false);
  //   });
  // };

  // useEffect(() => {
  //   getResolution(videoUrl);
  // }, [videoUrl]);

  // handle tab click to change
  const handleTabClick = (tabIndex) => {
    if (tabIndex === 0) {
      setCurrentTab("detailrelevantpartner")
    } else if (tabIndex === 1) {
      setCurrentTab("detailclifnotes")
    } else if (tabIndex === 2) {
      setCurrentTab("comments")
    }
  };

  return (
    <>
      {access2 ||
        (localStorage.getItem("remindmetomorrowcensus") &&
          moment(
            localStorage.getItem("remindmetomorrowcensus"),
            "YYYY-MM-DD"
          ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
          localStorage.getItem("dontshowcensuspopup") === "false" &&
          (access || access1)) ||
        ((localStorage.getItem("remindmetomorrowcensus") === null ||
          localStorage.getItem("remindmetomorrowcensus") === undefined) &&
          (localStorage.getItem("dontshowcensuspopup") === "false" ||
            localStorage.getItem("dontshowcensuspopup") === null ||
            localStorage.getItem("dontshowcensuspopup") === undefined) &&
          (access || access1)) ? (
        <CensusDataModal
          access={access}
          setAccess={setAccess}
          access1={access1}
          setAccess1={setAccess1}
          access2={access2}
          setAccess2={setAccess2}
        />
      ) : (
        <div className="cl-main-all-sec" id="frontend-video-detail">
          <div className="cstm-back-mn cstm-back-video-detail-cl">
            <div className="cstm-content-details-main">
              {loader ||
                !videodetail ||
                !videooptions ? (
                <div className="loading-video-loader">
                  <div className="skeleton-box" style={{ aspectRatio: '16 / 9', width: '100%', height: '100%', borderRadius: '12px', marginBottom: '24px' }}></div>
                  <div className="video-details-wrapper">
                    <div className="video-details-heading-text">
                      <div className="skeleton-box" style={{ width: '100%', marginBottom: '8px', height: '32px' }}></div>
                    </div>
                    <div className="video-details-buttons">
                      <div className="video-details-view-date">
                        <span className="video-view-text skeleton-box" style={{ height: '24px', width: '55px' }}></span>
                        <span className="video-uploded-date" style={{ height: '24px', width: '16px', flexShrink: '0' }}></span>
                        <p className="skeleton-box" style={{ height: '24px', width: '72px' }}></p>
                      </div>
                      <div className="video-details-btns">
                        <div className="btn-item">
                          <div className="skeleton-box" style={{ height: '40px', width: '40px', borderRadius: '50%' }}></div>
                        </div>
                        <div className="btn-item">
                          <div className="skeleton-box" style={{ height: '40px', width: '40px', borderRadius: '50%' }}></div>
                        </div>
                        <div className="btn-item">
                          <div className="skeleton-box" style={{ height: '40px', width: '40px', borderRadius: '50%' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="video-details-tabing">
                    <div className="cstm-vd-tabs">
                      <ul className="nav">
                        <li><a href="javascript: void(0)" className="active cstm-profile-link skeleton-box" style={{ width: '76px', height: '48px', borderRadius: '0', marginRight: '10px' }}></a></li>
                        <li><a href="javascript: void(0)" className="cstm-profile-link skeleton-box" style={{ width: '76px', height: '48px', borderRadius: '0', marginRight: '10px' }}></a></li>
                        <li><a href="javascript: void(0)" className="cstm-profile-link skeleton-box" style={{ width: '76px', height: '48px', borderRadius: '0', marginRight: '10px' }}></a></li>
                      </ul>
                    </div>
                    <div className="tab-content profile-details-tabs actibvwnvemn">
                      <div className="tab-pane fade active show">
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <p className="skeleton-box" style={{ width: '100%', height: '96px' }}></p>
                          </div>
                          <div className="text-wrapper">
                            <h5 className="skeleton-box" style={{ width: '100%', height: '24px' }}></h5>
                          </div>
                          <div className="text-wrapper">
                            <p className="skeleton-box" style={{ width: '100%', height: '24px' }}></p>
                            <p className="skeleton-box" style={{ width: '100%', height: '24px' }}></p>
                            <p className="skeleton-box" style={{ width: '100%', height: '24px' }}></p>
                          </div>
                          <div className="text-wrapper">
                            <div className="hashtag-list">
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                              <p className="skeleton-box" style={{ width: '90px', height: '20px' }}></p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <h3 className="skeleton-box" style={{ width: '100%', height: '32px' }}></h3>
                            <div className="chips-wrapper">
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                              <p className="skeleton-box" style={{ width: '140px', height: '34px', borderRadius: '555px' }}></p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <h3 className="skeleton-box" style={{ width: '100%', height: '32px' }}></h3>
                            <div className="speakers-wrapper">
                              <div className="speakers-item">
                                <div className="avtar-container relative avtar-48 skeleton-box" style={{ borderRadius: '505px' }}></div>
                                <div className="type-text-only">
                                  <h5 className="table-h5 skeleton-box" style={{ width: '100%', height: '20px' }}></h5>
                                  <h6 className="table-h6 skeleton-box" style={{ width: '100%', height: '18px' }}></h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <h3 className="skeleton-box" style={{ width: '100%', height: '32px' }}></h3>
                            <div className="related-event-video-card-list">
                              <div className="video-card small">
                                <div className="video-thumb-image img-img skeleton-box"></div>
                                <div className="video-card-text" style={{ width: '100%' }}>
                                  <h6 className="video-card-title skeleton-box" style={{ width: '100%', height: '20px' }}></h6>
                                  <div className="video-details-view-date skeleton-box" style={{ width: '100%', height: '18px' }}></div>
                                  <p className="video-type-text skeleton-box" style={{ width: '100%', height: '18px' }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <h3 className="skeleton-box" style={{ width: '100%', height: '32px' }}></h3>
                            <div className="related-event-video-card-list">
                              <div className="video-card small">
                                <div className="video-thumb-image img-img skeleton-box"></div>
                                <div className="video-card-text" style={{ width: '100%' }}>
                                  <h6 className="video-card-title skeleton-box" style={{ width: '100%', height: '20px' }}></h6>
                                  <div className="video-details-view-date skeleton-box" style={{ width: '100%', height: '18px' }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-conten">
                          <div className="text-wrapper">
                            <h3 className="skeleton-box" style={{ width: '100%', height: '32px' }}></h3>
                            <div className="related-event-video-card-list">
                              <div className="video-card small">
                                <div className="video-thumb-image img-img skeleton-box"></div>
                                <div className="video-card-text" style={{ width: '100%' }}>
                                  <h6 className="video-card-title skeleton-box" style={{ width: '100%', height: '20px' }}></h6>
                                  <p className="video-type-text skeleton-box" style={{ width: '100%', height: '18px' }}></p>
                                </div>
                              </div>
                              <div className="video-card small">
                                <div className="video-thumb-image img-img skeleton-box"></div>
                                <div className="video-card-text" style={{ width: '100%' }}>
                                  <h6 className="video-card-title skeleton-box" style={{ width: '100%', height: '20px' }}></h6>
                                  <p className="video-type-text skeleton-box" style={{ width: '100%', height: '18px' }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                selectedVideoId !== undefined &&
                selectedVideoId !== null &&
                selectedVideoId.length > 0 &&
                videodetail !== undefined &&
                videodetail !== null &&
                videooptions !== undefined &&
                videooptions !== null &&
                Object.keys(videodetail).length > 0 &&
                Object.keys(videooptions).length > 0 &&
                <>
                  <div className="cstm-content-details-video">
                    <VideoPlayer
                      loader={loader}
                      videooptions={videooptions}
                      changevideotime={changevideotime}
                      videodetail={videodetail}
                      setvideodetail={setvideodetail}
                      setvideooptions={setvideooptions}
                    />
                    {videodetail.relevant_partners.length > 0 && <div className="cstm-video-sponsor-card">
                      <div className="cstm-video-sponsor-icon"><img src={
                        videodetail.relevant_partners[0].logo ?
                          process.env
                            .REACT_APP_AWS_IMG_VID_PATH +
                          videodetail.relevant_partners[0].logo : mdsLogo
                      } /></div>
                      <h4 className="cstm-video-sponsor-name">{videodetail.relevant_partners[0].name}</h4>
                      <div className="cstm-video-sponsor-link">
                        <Link to={videodetail.relevant_partners[0].url} target="_blank">Visit website <IcnSLinkIcon /> </Link>
                      </div>
                    </div>}
                  </div>
                  <InfoTab
                    videodetail={videodetail}
                    setvideodetail={setvideodetail}
                    selectedVideoId={selectedVideoId}
                  />
                  <div className="video-details-tabing">
                    <TabsFluid
                      label=" Details"
                      hasBadge={false}
                      handleClick={handleTabClick}
                      hasBadge1={false}
                      label1="Cliff notes"
                      hasBadge2={false}
                      label2={`Comments `}
                      hasTab2={videodetail?.clif_notes &&
                        videodetail?.clif_notes?.length > 0 &&
                        videodetail?.clif_notes[0]?.length > 0}
                      hasTab3={true}
                      hasCounter={false}
                      hasCounter1={false}
                      hasCounter2={totalCommts > 0}
                      counter2={totalCommts}
                      isSelected={currentTab === "detailrelevantpartner" ? true : false}
                      isSelected1={currentTab === "detailclifnotes" ? true : false}
                      isSelected2={currentTab === "comments" ? true : false}
                    />

                    <div
                      className="tab-content profile-details-tabs actibvwnvemn"
                      id="myTabContent"
                    >
                      <div className={`tab-pane fade ${currentTab === "detailrelevantpartner"
                        ? "active show"
                        : ""
                        }`}
                        id="detailrelevantpartner"
                        role="tabpanel"
                        onClick={() => ("detailrelevantpartner")}
                      >
                        <DetailsTab videodetail={videodetail} />
                      </div>
                      <div className={`tab-pane fade ${videodetail.clif_notes &&
                        videodetail.clif_notes.length > 0 &&
                        videodetail.clif_notes[0].length > 0 && currentTab === "detailclifnotes"
                        ? "active show"
                        : ""
                        }`}
                        id="detailclifnotes"
                        role="tabpanel"
                        aria-labelledby="Groupposts-tab"
                        onClick={() => setCurrentTab("detailclifnotes")}
                      >
                        <div className="tab-conten">
                          <div className="cstm-clif-notes-mn clif-notes-wrapper">
                            {videodetail.clif_notes.length > 0 ? (
                              videodetail.clif_notes.map((note) => {
                                return (
                                  <>

                                    <p
                                      dangerouslySetInnerHTML={{ __html: note }}
                                    ></p>
                                  </>
                                );
                              })
                            ) : (
                              <div className="newsfeed-post">
                                <span className="emptymsg">No Data Found</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* 3 */}
                      <div className={`tab-pane fade ${currentTab === "comments"
                        ? "active show"
                        : ""
                        }`}
                        id="detailvideofiles"
                        role="tabpanel"
                        onClick={() => setCurrentTab("comments")}
                      >

                        <div className="text-wrapper" id="comments-scroll-sec">
                          <VideoCommentBox
                            allComments={allComments}
                            setAllComments={setAllComments}
                            setTotalCommts={setTotalCommts}
                            selectedVideoId={selectedVideoId}
                            totalCommts={totalCommts}
                            currentCommtPage={currentCommtPage}
                            setCurrentCommtPage={setCurrentCommtPage}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )}
              {
                like_popup ? (
                  <>
                    <div
                      className="modal fade create-popup-post cstm-tagfriend-popup"
                      id="video_likes_list"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <LikesListPopup data={videodetail.likes} />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            </div >
          </div >
        </div >
      )}
    </>
  );
};
export default ContentDetail;